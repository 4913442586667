import React, { useCallback } from 'react'
import useTemplate from '@hooks/useTemplate'
import { Input, Button, Typography } from '@wicadu/arepa/ui'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import TextWithCallToAction from '@components/molecules/TextWithCallToAction'

const texts = {
  title: 'Recuperar contraseña',
  description: 'Enviaremos las instrucciones para recuperar tu cuenta al siguente correo:',
  email_placeholder: 'Ingresar correo',
  confirm: 'Confirmar correo',
  remember_password: '¿Recordaste tu contraseña?',
  login: 'Iniciar sesión'
}

function ForgetPasswordTemplate () {
  const { onSubmit, loading } = useTemplate()

  const handleGoLogin = useCallback(() => {
    navigate('/login')
  }, [])

  return (
    <Wrapper className='row center-xs middle-sm'>
      <div className='col-xs-12 col-sm-12 col-md-4 col-lg-3'>
        <ContainerForm>
          <Typography type='title-4' weight={700}>{texts.title}</Typography>
          <Typography type='description'>{texts.description}</Typography>

          <ContentForm>
            <Input
              placeholder={texts.email_placeholder}
              name='email'
              htmlType='email'
              size='medium'
              fullWidth
            />
          </ContentForm>

          <Button onClick={onSubmit} size='medium' loading={loading}>{texts.confirm}</Button>

          <TextWithCallToAction
            text={texts.remember_password}
            toActionText={texts.login}
            toAction={handleGoLogin}
          />
        </ContainerForm>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & {
    margin: 0;
  }
`

const ContainerForm = styled.div`
  margin: 75px 10px; 
`

const ContentForm = styled.div`
  margin-top: 10px;

  div {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`

const ButtonContent = styled.div`
  & {
    i {
      margin: 0 8px;
    }
  }
`

export default ForgetPasswordTemplate
