import React, { Fragment } from 'react'
import SEO from '@components/SEO'
import ForgetPasswordContainer from '@containers/ForgetPassword'
import ForgetPasswordForm from '@HOCs/ForgetPasswordForm'

function ForgetPassword () {
  return (
    <Fragment>
      <SEO title='Recuperar contraseña' />

      <ForgetPasswordForm>
        <ForgetPasswordContainer />
      </ForgetPasswordForm>
    </Fragment>
  )
}

export default ForgetPassword
