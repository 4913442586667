import React, { useMemo } from 'react'
import { Form } from '@wicadu/arepa/ui'
import { yup, yupResolver } from '@wicadu/arepa/utils'
import PropTypes, { InferProps } from 'prop-types'

const propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func
}

const defaultProps = {
  onSubmit () {}
}

type Props = InferProps<typeof propTypes>

const schema = yup.object().shape({
  email: (
    yup.string()
      .wicaduEmail('Ingresa un correo válido')
      .required('El campo es requerido')
  )
})

function ForgetPasswordForm ({ children, onSubmit }: Props) {
  const opts = useMemo(() => ({
    mode: 'onSubmit',
    resolver: yupResolver(schema),
  }), [])

  return (
    <Form opts={opts} onSubmit={onSubmit}>{children}</Form>
  )
}

ForgetPasswordForm.propTypes = propTypes
ForgetPasswordForm.defaultProps = defaultProps

export default ForgetPasswordForm
