import React, { useCallback } from 'react'

import { navigate } from 'gatsby'
import { Form } from '@wicadu/arepa/ui'

import { useMutation } from '@apollo/client'
// import { FORGET_PASSWORD } from '@mutations/forgetPassword'
const FORGET_PASSWORD = ''

import { ForgetPassword } from '@ts-types/ForgetPassword'
import { TemplateProvider } from '@hooks/useTemplate'
import matchGraphQLErrors from '@utils/matchGraphQLErrors'
import ForgetPasswordTemplate from '@components/templates/ForgetPassword'

const { useForm } = Form

function ForgetPasswordContainer() {
  const { setError, handleSubmit } = useForm()

  const onCompleted = useCallback((): void => {
    navigate('/email-to-recover-password-sent')
  }, [])

  const onError = useCallback(
    ({ graphQLErrors }: any) => {
      const userNotFound: boolean = matchGraphQLErrors(graphQLErrors, 'user-not-found')

      if (userNotFound)
        return setError('email', {
          type: 'manual',
          message: 'No encontramos una cuenta asociada a este correo',
        })

      navigate('/error')
    },
    [setError]
  )

  const [forgetPassword, { loading }] = useMutation(FORGET_PASSWORD, { onCompleted, onError })

  const onSubmit = useCallback(
    handleSubmit(({ email }: ForgetPassword) => {
      forgetPassword({ variables: { email } })
    }),
    [handleSubmit, forgetPassword]
  )

  return (
    <TemplateProvider value={{ onSubmit, loading }}>
      <ForgetPasswordTemplate />
    </TemplateProvider>
  )
}

export default ForgetPasswordContainer
